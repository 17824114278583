<template>
  <div
    class="py-8 min-h-100vh-mobile-menu overflow-hidden bg-whiteSmoke flex items-center md:min-h-100vh-menu"
  >
    <div
      class="content-container flex flex-col mt-8 lg:mt-24 xl:flex-row xl:items-center xl:justify-between xl:mt-0"
    >
      <controller-product
        v-slot="{ item, status, options, addToCart }"
        class="relative z-10 flex-shrink-0 -mb-12 sm:w-50rem sm:-mb-56 md:-mb-18rem xl:mb-0 xl:-mr-20rem xl:mb-48"
      >
        <p
          class="text-4xl leading-tight font-pragmatica-extended sm:text-3.6rem"
        >
          {{ item.name }}
        </p>
        <div class="mb-22px mt-5">
          <editor-link v-slot="{ name, path }" name="productDetailsLink">
            <cms-link :to="path">
              <p :key="name" class="text-p flex items-center cursor-pointer">
                <span class="pr-2 underline hover:text-lightGray">
                  {{ $t('hsc.post.productDetails') }}
                </span>
                <span>
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 0.9188L5.84 5.0812L10.68 0.918802"
                      stroke="#9B9B9B"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                  </svg>
                </span>
              </p>
            </cms-link>
          </editor-link>
        </div>
        <div>
          <controller-user-comment
            ref="userCommentComponent"
            class="inline-block"
          >
            <div
              class="pb-3.5rem pl-16px flex items-center hover:text-lightGray"
              @click="leaveAReview"
            >
              <RatingStarsComponent :bg-gray="true" />
              <span class="text-p leading-5 h-full align-middle">{{
                $t('hsc.post.leaveReview')
              }}</span>
            </div>
          </controller-user-comment>
        </div>
        <div class="flex items-center mb-12 sm:mb-24">
          <p
            class="pr-6 text-2xl leading-snug font-pragmatica-extended flex-shrink-0 sm:text-2.4rem sm:pr-8"
          >
            <span class="flex flex-col">
              <span>
                {{ $currency(item.retailPrice) }}
              </span>
              <span
                v-if="returnEuroPrice(item.retailPrice)"
                class="text-ps ml-auto"
              >
                ({{ returnEuroPrice(item.retailPrice) }})
              </span>
            </span>
          </p>

          <editor-tip-tap
            name="shortDescription"
            class="h-full pl-6 border-l border-darkGrayishViolet text-lg leading-tight box-content sm:max-w-24rem sm:text-1.4rem sm:pl-8"
          />
        </div>
        <div class="inline-flex flex-col">
          <div class="flex items-center">
            <custom-number-input v-model.number="options.quantity" />
            <multiple-bordered-button
              class="ml-6 sm:ml-12"
              primary-color-name="pureYellow"
              text-color-name="darkGrayishViolet"
              :disabled="status.pending"
              @clicked="addToCart"
            >
              <span class="btn-text">{{
                $t('hsc.form.actions.addToCart')
              }}</span>
            </multiple-bordered-button>
          </div>

          <hr class="border-lightGrayishBlue mt-12 sm:mt-24" />
        </div>

        <div class="mt-4 text-lg leading-tight flex sm:mt-6 sm:text-1.4rem">
          <editor-tip-tap name="contactText" />
          <editor-link
            v-slot="{ name, path }"
            name="contactLink"
            class="ml-2 font-extrabold"
          >
            <cms-link :to="path" class="transitioned hover:text-pureYellow">
              {{ name }}
            </cms-link>
          </editor-link>
        </div>
      </controller-product>
      <div
        class="relative self-end flex items-center justify-center -mr-24 w-32rem h-32rem sm:w-55rem sm:h-55rem sm:-mr-40 lg:w-65rem lg:h-65rem xl:w-100rem xl:h-103rem xl:self-center xl:-mr-20rem xl:-mt-24 xl:-mb-8"
      >
        <hexagon-svg class="absolute top-0 w-full h-full" light />
        <editor-image
          name="productImage"
          class="h-75% w-55% flex items-center justify-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ControllerUserComment from 'Controllers/ControllerUserComment'
import ControllerProduct from 'Controllers/ControllerProduct'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import EditorImage from 'Editors/EditorImage'
import EditorTipTap from 'Editors/EditorTipTap'
import EditorLink from 'Editors/EditorLink'
import CustomNumberInput from '@/components/ui/inputs/CustomNumberInput'
import HexagonSvg from '@/components/ui/svg/HexagonSvg'
import RatingStarsComponent from '@/components/ui/inputs/RatingStarsComponent.vue'

export default {
  components: {
    ControllerProduct,
    RatingStarsComponent,
    ControllerUserComment,
    MultipleBorderedButton,
    CustomNumberInput,
    EditorImage,
    EditorTipTap,
    EditorLink,
    HexagonSvg,
  },
  methods: {
    leaveAReview() {
      this.$root.$emit('leave-review-form', true)
    },
  },
}
</script>
<style scoped>
::v-deep img {
  @apply max-h-full max-w-full h-auto w-auto object-center object-contain;
}
</style>
