<template>
  <div class="view-container">
    <div class="content-container">
      <editor-tip-tap
        name="contactTitle"
        content-class="main-title mb-12 overflow-hidden font-extrabold leading-tight font-pragmatica-extended lg:mb-7rem"
      />
      <div
        class="flex flex-col items-start justify-between lg:flex-row lg:items-start"
      >
        <div class="mb-12 md:mb-16 lg:pt-8 lg:mb-0 lg:mr-8 lg:w-2/5">
          <editor-tip-tap
            name="contactSubtitle"
            class="mb-12 main-subtitle leading-tight font-pragmatica-extended"
          />
          <editor-tip-tap
            name="contactInfo"
            class="text-xl leading-loose md:text-1.6rem"
          />
        </div>
        <controller-contact-form
          v-slot="{ form, submit, consents, generateErrorMessage }"
          :required-fields="['from', 'subject', 'content']"
          class="flex-shrink-0 self-center w-full px-5 pt-5 pb-6 bg-whiteSmoke rounded-lg sm:w-5/6 lg:w-3/5 md:px-8 md:pt-8 md:pb-10 xl:w-1/2"
        >
          <editor-tip-tap
            name="contactFormDescription"
            class="form-label font-pragmatica-extended"
          />
          <form class="mt-12 lg:mt-7rem" @submit.prevent="submit">
            <section class="mb-5">
              <label class="form-label">
                {{ $t('hsc.form.inputs.email') }}*
                <input
                  v-model.trim="form.from"
                  type="email"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.email')"
                />
              </label>
              <p v-show="generateErrorMessage('form.from')" class="error-msg">
                {{ generateErrorMessage('form.from') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label">
                {{ $t('hsc.form.inputs.subject') }}*
                <input
                  v-model.trim="form.subject"
                  type="text"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.subject')"
                />
              </label>
              <p
                v-show="generateErrorMessage('form.subject')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.subject') }}
              </p>
            </section>
            <section class="mb-5">
              <label class="form-label">
                {{ $t('hsc.form.inputs.message') }}*
                <textarea
                  v-model.trim="form.content"
                  rows="10"
                  class="form-text form-input placeholder-lightGrayishBlue focus:outline-none"
                  :placeholder="$t('hsc.form.inputs.typeMessage')"
                />
              </label>
              <p
                v-show="generateErrorMessage('form.content')"
                class="error-msg"
              >
                {{ generateErrorMessage('form.content') }}
              </p>
            </section>
            <section
              class="mb-10 form-text grid grid-cols-1 gap-6 md:mb-4.5rem"
            >
              <div
                v-for="consent in consents"
                :key="consent.uniqueNumber"
                class="flex items-center"
              >
                <input
                  v-model="form.acceptedConsents"
                  type="checkbox"
                  :value="consent.uniqueNumber"
                  class="mr-2 md:mr-4 md:w-8 md:h-8"
                />
                <div
                  class="leading-tight inline"
                  v-html="consent.displayName"
                />
              </div>
            </section>
            <section>
              <multiple-bordered-button
                primary-color-name="darkGrayishViolet"
                text-color-name="white"
                is-submit
                :disabled="form.acceptedConsents.length !== consents.length"
              >
                <span class="btn-text">{{ $t('hsc.form.actions.send') }}</span>
              </multiple-bordered-button>
            </section>
            <recaptcha />
          </form>
        </controller-contact-form>
      </div>
    </div>
  </div>
</template>

<script>
import ControllerContactForm from 'Controllers/ControllerContactForm'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import EditorTipTap from 'Editors/EditorTipTap'
export default {
  components: {
    EditorTipTap,
    MultipleBorderedButton,
    ControllerContactForm,
  },
}
</script>
