<template>
  <div class="pt-40 pb-16 md:pt-20rem md:pb-32">
    <div class="content-container">
      <editor-tip-tap
        name="mainTitle"
        content-class="main-title mb-12 overflow-hidden font-extrabold leading-tight font-pragmatica-extended md:mb-7rem"
      />
      <editor-tip-tap
        name="mainSubtitle"
        class="main-subtitle mb-12 leading-tight font-pragmatica-extended md:mb-7rem"
      />

      <div class="w-11/12 lg:max-w-73rem mx-auto">
        <editor-list
          v-slot="{ item }"
          name="privacyPolicy"
          class="privacy-policy-text"
          item-class="mb-10 md:mb-16"
          :item-options="options"
        >
          <div class="font-bold font-pragmatica-extended" v-html="item.title" />

          <div class="leading-normal" v-html="item.description" />
          <div v-if="item.image">
            <div class="mt-4 lg:mt-6 mb-6 lg:mb-10">
              <cms-image class="max-w-24rem" :media="item.image" />
            </div>
          </div>
        </editor-list>
      </div>
    </div>
  </div>
</template>
<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorList from 'Editors/EditorList'

export default {
  components: {
    EditorTipTap,
    EditorList,
  },
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Title',
          type: 'tipTap',
        },
        {
          name: 'description',
          label: 'Description',
          type: 'tipTap',
        },
        {
          name: 'image',
          label: 'Slika',
          type: 'image',
        },
      ],
    }
  },
}
</script>

<style scoped>
.privacy-policy-text {
  @apply text-xl leading-loose;
}

@media only screen and (min-width: 768px) {
  .privacy-policy-text {
    @apply text-1.6rem;
  }
}

::v-deep a {
  @apply text-blue-600;
}

::v-deep a:hover {
  @apply underline;
}

::v-deep ul,
ol {
  @apply pl-10;
}

::v-deep ul li {
  @apply list-disc;
}

::v-deep ol li {
  @apply list-decimal;
}
</style>
