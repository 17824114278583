<template>
  <div
    class="py-8 min-h-100vh-mobile-menu overflow-hidden flex items-center md:min-h-100vh-menu"
    :class="`bg-${bgColor} text-${textColor}`"
  >
    <div
      class="content-container flex flex-col mt-8 lg:mt-24 xl:flex-row xl:items-center xl:justify-between xl:mt-0"
    >
      <div
        class="relative z-10 flex-shrink-0 -mb-16 sm:-mb-56 md:-mb-18rem xl:mb-0 xl:-mr-40rem xl:mb-32"
      >
        <editor-tip-tap
          name="productSingleHeroTitle"
          content-class="main-title mb-6 overflow-hidden font-extrabold leading-tight font-pragmatica-extended md:max-w-71rem md:mb-12"
        />

        <div class="sm:max-w-50rem">
          <editor-tip-tap
            name="productSingleHeroSubtitle"
            class="main-subtitle mb-5 text-pureYellow leading-tight font-pragmatica-extended md:mb-10"
          />

          <editor-tip-tap
            name="productSingleHeroDescription"
            class="mb-8 text-xl leading-loose font-pragmatica-extended md:text-1.6rem md:mb-8.5rem"
          />

          <div class="flex items-center">
            <div>
              <multiple-bordered-button
                primary-color-name="pureYellow"
                text-color-name="darkGrayishViolet"
              >
                <editor-link
                  v-slot="{ name, path }"
                  name="productSingleHeroBuyLink"
                  class="w-full h-full"
                >
                  <cms-link :to="path" class="btn-text">
                    {{ name }}
                  </cms-link>
                </editor-link>
              </multiple-bordered-button>
            </div>

            <div
              class="h-full w-full pl-4 ml-6 border-l truncate text-2xl leading-snug font-pragmatica-extended md:text-2.4rem md:pl-8 md:ml-12"
              :class="`border-${textColor}`"
            >
              <editor-product-list :name="editorProductListName">
                <template #list="{ items }">
                  <controller-product
                    v-for="product in items"
                    :key="product.id"
                    v-slot="{ item }"
                  >
                    <span class="flex flex-col">
                      <span>
                        {{ $currency(item.retailPrice) }}
                      </span>
                      <span
                        v-if="returnEuroPrice(item.retailPrice)"
                        class="text-ps"
                      >
                        ({{ returnEuroPrice(item.retailPrice) }})
                      </span>
                    </span>
                  </controller-product>
                </template>
              </editor-product-list>
            </div>
          </div>
        </div>
      </div>

      <div
        class="relative self-end flex items-center justify-center -mr-24 w-32rem h-32rem sm:w-55rem sm:h-55rem sm:-mr-40 lg:w-65rem lg:h-65rem xl:w-100rem xl:h-103rem xl:self-center xl:-mr-22rem xl:-my-16"
      >
        <hexagon-svg
          class="absolute top-0 w-full h-full"
          :light="useLightHexagon"
        />
        <editor-image
          name="productSingleHeroImage"
          class="h-75% w-55% flex items-center justify-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorLink from 'Editors/EditorLink'
import EditorImage from 'Editors/EditorImage'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import EditorProductList from 'Editors/EditorProductList'
import ControllerProduct from 'Controllers/ControllerProduct'
import HexagonSvg from '@/components/ui/svg/HexagonSvg'

export default {
  components: {
    EditorTipTap,
    EditorLink,
    EditorImage,
    EditorProductList,
    MultipleBorderedButton,
    ControllerProduct,
    HexagonSvg,
  },
  data() {
    return {
      bgColor: 'darkGrayishViolet',
      textColor: 'white',
      editorProductListName: 'smartNeedlePriceProductList',
      useLightHexagon: false,
    }
  },
}
</script>
<style scoped>
::v-deep img {
  @apply max-h-full max-w-full h-auto w-auto object-center object-contain;
}
</style>
