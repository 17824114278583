<template>
  <div
    class="bg-hexagon-background bg-whiteSmoke bg-center bg-cover bg-no-repeat py-32 sm:bg-auto md:py-56 md:min-h-screen md:flex md:items-center md:justify-center"
  >
    <div
      class="content-container grid grid-cols-1 auto-rows-1fr gap-4 md:gap-6"
    >
      <!-- TOP ROW -->
      <div class="flex items-center justify-center self-end">
        <!-- CENTERED DESCRIPTION -->
        <div class="w-3/4 md:w-35rem text-center">
          <editor-tip-tap name="description1Title" class="description-title" />
          <editor-tip-tap name="description1Text" class="description-text" />
        </div>
      </div>

      <!-- MIDDLE ROW -->
      <div class="flex items-center justify-between">
        <!-- LEFT DESCRIPTION -->
        <div class="w-1/4 lg:w-27rem">
          <editor-tip-tap name="description4Title" class="description-title" />
          <editor-tip-tap name="description4Text" class="description-text" />
        </div>

        <!-- IMAGE -->
        <img
          id="details-image"
          src="/images/smart-card/smart-card-details.png"
          class="w-1/2 h-auto mx-1 lg:w-53rem lg:h-32.5rem sm:mx-3 lg:mx-6"
        />

        <!-- RIGHT DESCRIPTION -->
        <div class="w-1/4 lg:w-27rem">
          <editor-tip-tap name="description2Title" class="description-title" />
          <editor-tip-tap name="description2Text" class="description-text" />
        </div>
      </div>

      <!-- BOTTOM ROW -->
      <div class="flex flex-col items-center justify-center self-start">
        <!-- CENTERED DESCRIPTION -->
        <div class="w-80% md:w-35rem text-center mb-6">
          <editor-tip-tap name="description3Title" class="description-title" />
          <editor-tip-tap name="description3Text" class="description-text" />
        </div>
        <a
          v-tooltip="{
            content: certificateTooltipContent(253),
          }"
          :href="
            isCroatianLanguage
              ? '/documents/certificate-253-hr.pdf'
              : '/documents/certificate-253-en.pdf'
          "
          target="_blank"
        >
          <img
            src="/images/certificates/certificate253.png"
            class="transitioned h-24 w-32 md:h-40 md:w-48 xl:h-12.5rem xl:w-15rem transform hover:scale-105"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import EditorTipTap from 'Editors/EditorTipTap'
import { VTooltip } from 'v-tooltip'
export default {
  components: {
    EditorTipTap,
  },

  directives: {
    tooltip: VTooltip,
  },

  computed: {
    isCroatianLanguage() {
      return this.$application.language.code2 === 'hr'
    },
  },

  methods: {
    certificateTooltipContent(number) {
      return `<p>${this.$t('hsc.certificates.certificate')} ${number}:
       <span class="font-semibold uppercase">${this.$t(
         `hsc.certificates.${number}`
       )}</span>
      <br/><span class="italic">${this.$t(
        'hsc.certificates.clickToOpen'
      )}</span>
      </p>`
    },
  },
}
</script>

<style scoped>
.description-title {
  @apply w-full font-pragmatica-extended leading-tight font-bold text-base;
}

.description-text {
  @apply w-full text-xs;
}

::v-deep #description-image {
  @apply w-full h-full object-contain object-center;
}

@media only screen and (min-width: 640px) {
  .description-title {
    @apply text-xl;
  }

  .description-text {
    @apply text-base;
  }
}

@media only screen and (min-width: 768px) {
  .description-title {
    @apply text-3xl;
  }

  .description-text {
    @apply text-xl;
  }
}

@media only screen and (min-width: 1024px) {
  .description-title {
    @apply text-2rem;
  }

  .description-text {
    @apply text-1.6rem;
  }
}
</style>
