<template>
  <div>
    <div class="full-width-content-container md:py-11rem">
      <editor-list
        name="deckGalleryList"
        :item-options="options"
        class="overflow-hidden"
        add-button-class="flex items-center justify-center"
        @items-loaded="recreateDeckGallery"
      >
        <template #list="{ items }">
          <!--  gallery is destroyed and recreated on resize because we want to calculate its new dimensions-->
          <deck-gallery-component :key="componentKey" :list-items="items" />
        </template>
      </editor-list>
    </div>
  </div>
</template>
<script>
import DeckGalleryComponent from '@/components/ui/gallery/DeckGalleryComponent'
import EditorList from 'Editors/EditorList'

import DateFormatter from '~/mixins/DateFormatter'
export default {
  components: {
    DeckGalleryComponent,

    EditorList,
  },
  mixins: [DateFormatter],
  data() {
    return {
      isSent: false,
      componentKey: 1234576,
      options: [
        {
          name: 'image',
          label: 'Image',
          type: 'image',
        },
      ],
    }
  },
  mounted() {
    this.$nuxt.$on('custom-resize', this.recreateDeckGallery)
  },
  beforeDestroy() {
    this.$nuxt.$off('custom-resize')
  },

  methods: {
    recreateDeckGallery() {
      this.componentKey++
    },
  },
}
</script>
<style lang="scss" scoped>
input[type='checkbox'] {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  color: black;
  background: #f6f6f6;
  border: 1px solid #eeeeee;
  appearance: none;
  outline: 0;
  cursor: pointer;
  &::before {
    position: absolute;
    content: '';
    display: block;
    left: 6px;
    width: 7px;
    height: 14px;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
    opacity: 0;
  }
  &:checked {
    color: #eeeeee;
    border-color: #365a6e;
    background: #365a6e;
    &::before {
      opacity: 1;
    }
  }
}
input,
button {
  font-size: 14px;
  line-height: 20px;
}
::placeholder {
  font-size: 14px;
  line-height: 20px;
}
</style>
