<template>
  <div class="pt-40 md:pt-13rem xl:pt-20rem">
    <div class="content-container mb-24 md:mb-48">
      <editor-tip-tap
        name="croDistributersTitle"
        content-class="main-title mb-16 overflow-hidden font-extrabold leading-tight font-pragmatica-extended md:mb-40"
      />

      <editor-list
        v-slot="{ item }"
        name="croatianDistributerList"
        :item-options="options"
        class="flex flex-col items-center justify-center -mx-5 -my-5 xl:flex-row xl:flex-wrap xl:items-stretch xl:-mx-3 xl:-my-3"
        item-class="flex flex-col w-full sm:w-2/3 xl:w-1/3 px-5 py-5 overflow-x-hidden xl:px-3 xl:py-3"
      >
        <p
          v-show="item.city"
          class="px-3 text-center text-2xl leading-normal font-pragmatica-extended md:text-2.4rem"
        >
          {{ item.city }}
        </p>

        <div
          class="flex-grow flex flex-col items-center justify-center bg-whiteSmoke py-5 px-3 mt-6 xl:justify-start sm:mt-10 sm:py-8"
        >
          <div class="overflow-hidden">
            <p
              v-show="item.companyName"
              class="text-center text-xl leading-normal font-pragmatica-extended font-bold md:text-1.6rem"
            >
              {{ item.companyName }}
            </p>

            <div
              class="mt-6 text-center text-xl leading-loose sm:mt-10 md:text-1.6rem"
            >
              <p v-show="item.address">{{ $t('hsc.form.inputs.address') }}:</p>
              <p v-show="item.address">
                {{ item.address }}
              </p>

              <p v-show="item.municipality">{{ item.municipality }}</p>

              <p v-show="item.phone">
                {{ $t('hsc.distributers.phone') }}:
                <a :href="`tel:${item.phone}`" class="hover:underline">{{
                  item.phone
                }}</a>
              </p>

              <p v-show="item.email">
                {{ $t('hsc.form.inputs.email') }}:
                <a :href="`mailto:${item.email}`" class="hover:underline">{{
                  item.email
                }}</a>
              </p>
            </div>

            <a
              v-show="item.webLink"
              :href="item.webLink"
              target="_blank"
              class="block text-center text-center text-xl leading-normal font-bold mt-6 sm:mt-10 md:text-1.6rem hover:underline"
            >
              {{ item.webLink && calculateLinkLabel(item.webLink) }}
            </a>
          </div>
        </div>
      </editor-list>
    </div>
    <div class="full-width-content-container">
      <editor-map
        scroll-wheel-zoom
        name="map"
        class="mx-auto w-full h-35rem sm:h-55rem"
        related-editor-list-name="croatianDistributerList"
      >
        <template #popup="{ index, item }">
          <div>
            <p class="uppercase font-semibold">{{ item.companyName }}</p>
            <p v-show="item.address">{{ item.address }}, {{ item.city }}</p>
            <p v-show="item.muncipality">{{ item.muncipality }}</p>
            <p v-show="item.phone">
              {{ $t('hsc.distributers.phone') }}:
              <a :href="`tel:${item.phone}`" class="hover:underline">{{
                item.phone
              }}</a>
            </p>
            <p v-show="item.email">
              {{ $t('hsc.form.inputs.email') }}:
              <a :href="`mailto:${item.email}`" class="hover:underline">{{
                item.email
              }}</a>
            </p>
            <a
              v-show="item.webLink"
              :href="item.webLink"
              target="_blank"
              class="block text-blue-600 hover:underline"
            >
              {{ item.webLink && calculateLinkLabel(item.webLink) }}
            </a>
          </div>
        </template>
      </editor-map>
    </div>
  </div>
</template>
<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorList from 'Editors/EditorList'
import EditorMap from 'Editors/EditorMap'

export default {
  components: {
    EditorTipTap,
    EditorList,
    EditorMap,
  },

  data() {
    return {
      clickedQuestionIndex: null,

      options: [
        {
          name: 'city',
          label: 'Grad',
          type: 'textarea',
        },
        {
          name: 'companyName',
          label: 'Distributer',
          type: 'textarea',
        },
        {
          name: 'address',
          label: 'Adresa',
          type: 'textarea',
        },
        {
          name: 'municipality',
          label: 'Općina',
          type: 'textarea',
        },
        {
          name: 'phone',
          label: 'Broj za kontakt',
          type: 'text',
        },
        {
          name: 'email',
          label: 'Email',
          type: 'text',
        },
        {
          name: 'webLink',
          label: 'Poveznica',
          type: 'text',
        },
        {
          name: 'location',
          label: 'Lokacija',
          type: 'coordinates',
        },
      ],
    }
  },
  methods: {
    calculateLinkLabel(link) {
      if (link.substring(0, 7) === 'http://') {
        if (link.charAt(link.length - 1) === '/')
          return link.substring(7, link.length - 1)
        return link.substring(7)
      }
      if (link.substring(0, 8) === 'https://') {
        if (link.charAt(link.length - 1) === '/')
          return link.substring(8, link.length - 1)
        return link.substring(8)
      } else return link
    },
  },
}
</script>
