<template>
  <div id="ProductDetails" class="pt-40px lg:pt-100px">
    <editor-image name="imageSection" />
  </div>
</template>

<script>
import EditorImage from 'Editors/EditorImage'
export default {
  components: {
    EditorImage,
  },
}
</script>

<style lang="scss" scoped></style>
