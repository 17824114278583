<template>
  <div class="full-width-content-container md:py-11rem">
    <editor-list
      name="descriptionGalleryList"
      add-button-class="flex items-center justify-center"
      :item-options="options"
      @items-loaded="recreateDescriptionGallery"
    >
      <template #list="{ items }">
        <!--  gallery is destroyed and recreated on resize because we want to calculate its new dimensions-->
        <description-gallery-component
          :key="componentKey"
          :list-items="items"
        />
      </template>
    </editor-list>
  </div>
</template>
<script>
import EditorList from 'Editors/EditorList'
import DescriptionGalleryComponent from '@/components/ui/gallery/DescriptionGalleryComponent'

export default {
  components: {
    EditorList,
    DescriptionGalleryComponent,
  },

  data() {
    return {
      componentKey: 226423,
      options: [
        {
          name: 'image',
          label: 'Image',
          type: 'image',
        },
      ],
    }
  },

  mounted() {
    this.$nuxt.$on('custom-resize', this.recreateDescriptionGallery)
  },
  beforeDestroy() {
    this.$nuxt.$off('custom-resize')
  },

  methods: {
    recreateDescriptionGallery() {
      this.componentKey++
    },
  },
}
</script>
