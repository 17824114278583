var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"star-icon"},_vm._l((5),function(i){return _c('i',{key:i,class:[
      { activeHover: _vm.returnValue },
      +_vm.rating === 0 && i <= _vm.hoverIndex
        ? 'filled icon-icon-filled-star'
        : 'icon-ic-actions-star',
      _vm.rating === 0 && i <= _vm.index && !_vm.hoverIndex
        ? 'filled icon-icon-filled-star'
        : 'icon-ic-actions-star',
      i <= _vm.rating && _vm.index === 0
        ? 'filled icon-icon-filled-star'
        : 'icon-ic-actions-star',
      { bg_gray: _vm.bgGray },
    ],on:{"click":function($event){_vm.returnValue && _vm.setActiveStar(i)},"mouseenter":function($event){_vm.returnValue && _vm.setActiveHoverStar(i)},"mouseleave":function($event){_vm.returnValue && _vm.setActiveHoverStar(0)}}})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }