<template>
  <div ref="deckGalleryContainer" :style="`height:${galleryHeight}px;`">
    <div
      v-if="!enoughSlides"
      class="h-full w-full flex items-center justify-center p-5 bg-whiteSmoke uppercase text-3xl font-pragmatica-extended font-bold"
    >
      <span
        >{{ 3 - listItems.length }} more
        {{ 3 - listItems.length === 1 ? 'image' : 'images' }} required.</span
      >
    </div>
    <div v-else class="h-full w-full">
      <!-- TABLET/LAPTOP GALLERY -->
      <div class="hidden relative z-10 h-full w-full overflow-hidden xl:block">
        <editor-list-item
          v-for="item in listItems"
          :key="`${item.$id}-web`"
          ref="slide"
          :item-id="item.$id"
          class="slide"
        >
          <cms-image
            :media="item.image"
            class="w-full h-full object-cover object-center"
          />
        </editor-list-item>
        <!-- TABLET/LAPTOP PAGINATION AND BUTTONS -->
        <div
          class="absolute bottom-5% right-5% transform translate-y-50% w-1/4 flex items-center justify-between lg:bottom-7%"
        >
          <div class="flex items-center justify-center space-x-6">
            <button
              class="gallery-button transitioned"
              @click="webSlidePrevious"
            >
              <fa :icon="icons.basic.leftArrow" class="transitioned" />
            </button>
            <button class="gallery-button transitioned" @click="webSlideNext">
              <fa :icon="icons.basic.rightArrow" class="transitioned" />
            </button>
          </div>

          <p class="font-pragmatica-extended font-bold text-1.6rem">
            {{ activeIndex + 1 }} / {{ listItems.length }}
          </p>
        </div>
      </div>

      <!-- MOBILE GALLERY -->
      <div class="relative w-full h-full overflow-hidden xl:hidden">
        <transition-group :name="mobileSlideTransitionName">
          <editor-list-item
            v-for="(item, index) in listItems"
            v-show="activeIndex === index"
            :key="`${item.$id}-mobile`"
            :item-id="item.$id"
            class="mobile-slide"
          >
            <cms-image
              :media="item.image"
              class="w-full h-full object-cover object-center"
            />
          </editor-list-item>
        </transition-group>
        <!-- MOBILE BUTTONS -->
        <div
          class="absolute w-full h-full flex items-center justify-between p-5%"
        >
          <button
            class="gallery-button transitioned"
            @click="mobileSlidePrevious"
          >
            <fa :icon="icons.basic.leftArrow" class="transitioned" />
          </button>
          <button class="gallery-button transitioned" @click="mobileSlideNext">
            <fa :icon="icons.basic.rightArrow" class="transitioned" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FaIcons from '@/mixins/FaIcons'
import EditorListItem from 'Editors/EditorListItem'
import debounce from 'lodash.debounce'

export default {
  components: {
    EditorListItem,
  },
  mixins: [FaIcons],
  props: {
    listItems: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      activeIndex: 0,
      galleryWidth: 0,
      slideElements: [],
      web: {
        isTransitioning: false,
      },
      mobile: {
        slideModes: null,
        activeSlideMode: null,
      },
    }
  },
  computed: {
    galleryHeight() {
      if (this.galleryWidth < 640) return Math.round(this.galleryWidth * 0.75)
      if (this.galleryWidth < 1024) return Math.round(this.galleryWidth * 0.65)
      return Math.round(this.galleryWidth * 0.45)
    },
    enoughSlides() {
      return this.listItems.length > 2
    },
    nextIndex() {
      if (this.activeIndex === this.listItems.length - 1) return 0
      return this.activeIndex + 1
    },
    previousIndex() {
      if (this.activeIndex === 0) return this.listItems.length - 1
      return this.activeIndex - 1
    },
    mobileSlideTransitionName() {
      return this.mobile.activeSlideMode === this.mobile.slideModes.previous
        ? 'slide-right'
        : 'slide-left'
    },
  },

  created() {
    const slideModes = {
      previous: 1,
      next: 2,
    }
    Object.freeze(slideModes)
    this.mobile.slideModes = slideModes
    this.mobile.activeSlideMode = slideModes.next
  },

  mounted() {
    this.calculateGalleryWidth()
    if (this.$refs.slide && this.$refs.slide.length) {
      this.saveSlideElements()
      this.setStartingClasses()
    }
  },

  methods: {
    calculateGalleryWidth() {
      this.galleryWidth =
        this.$refs.deckGalleryContainer.getBoundingClientRect().width
    },
    saveSlideElements() {
      this.$refs.slide.forEach((slideRef) => {
        this.slideElements.push(slideRef.$el)
      })
    },
    setStartingClasses() {
      this.slideElements[this.activeIndex].classList.add('active-slide')
      this.slideElements[this.activeIndex].classList.add('z-20')
      this.slideElements[this.nextIndex].classList.add('z-10')
    },
    // DO NOT TOUCH THIS FOR THE LOVE OF GOD
    webSlidePrevious: debounce(function () {
      if (this.web.isTransitioning) return
      this.web.isTransitioning = true

      const afterTransition = () => {
        this.activeIndex = this.previousIndex

        this.slideElements[this.nextIndex].classList.add('z-10')
        this.slideElements[this.nextIndex].classList.remove('z-20')

        this.slideElements[this.activeIndex].classList.add('z-20')

        this.slideElements[oldNextIndex].classList.remove('z-5')

        this.slideElements[this.nextIndex].removeEventListener(
          'transitionend',
          afterTransition
        )
        this.web.isTransitioning = false
      }

      this.slideElements[this.activeIndex].addEventListener(
        'transitionend',
        afterTransition
      )
      this.slideElements[this.nextIndex].classList.add('z-5')
      this.slideElements[this.nextIndex].classList.remove('z-10')

      const oldNextIndex = this.nextIndex

      this.slideElements[this.activeIndex].classList.remove('active-slide')
      this.slideElements[this.previousIndex].classList.add('active-slide')
    }, 200),

    // SAME GOES FOR THIS
    webSlideNext: debounce(function () {
      if (this.web.isTransitioning) return
      this.web.isTransitioning = true
      // Increase z-index on next active slide so that it stays in front during transition
      this.slideElements[this.nextIndex].classList.add('z-20')

      setTimeout(() => {
        // Remove old z-index on it
        this.slideElements[this.nextIndex].classList.remove('z-10')
        // Remove z-index from active slide because it should go to back of the queue
        this.slideElements[this.activeIndex].classList.remove('z-20')

        // Change active index to next index and before transitioning, new next slide has to be on top of the queue so add z-index to it
        this.activeIndex = this.nextIndex
        this.slideElements[this.nextIndex].classList.add('z-10')

        // Apply transition now
        this.slideElements[this.previousIndex].classList.remove('active-slide')
        this.slideElements[this.activeIndex].classList.add('active-slide')
        this.web.isTransitioning = false
      }, 100)
    }, 200),

    mobileSlidePrevious: debounce(function () {
      this.mobile.activeSlideMode = this.mobile.slideModes.previous
      this.activeIndex = this.previousIndex
    }, 200),

    mobileSlideNext: debounce(function () {
      this.mobile.activeSlideMode = this.mobile.slideModes.next
      this.activeIndex = this.nextIndex
    }, 200),
  },
}
</script>
<style scoped>
.mobile-slide {
  @apply w-full h-full absolute top-0 !important;
}

::v-deep .slide {
  @apply absolute h-70% w-1/4 right-5% transition-all duration-400 ease-in-out top-50% transform -translate-y-1/2;
}
.active-slide {
  @apply w-65% h-full right-35% top-0 transform translate-y-0 !important;
}

.gallery-button {
  @apply w-10 h-10 rounded-full text-2xl text-darkGrayishViolet bg-white border border-lightGrayishBlue flex-shrink-0 flex items-center justify-center;
}
.gallery-button:focus {
  @apply outline-none;
}

.gallery-button:hover {
  @apply bg-pureYellow border-pureYellow;
}
@media only screen and (min-width: 768px) {
  .gallery-button {
    @apply w-16 h-16;
  }
}

@media only screen and (min-width: 1280px) {
  .gallery-button:hover svg {
    @apply transform scale-125;
  }
}
</style>
