<template>
  <div ref="descriptionGalleryContainer" :style="`height:${galleryHeight}px;`">
    <div
      v-if="!isGalleryShown"
      class="h-full w-full flex items-center justify-center p-5 bg-whiteSmoke uppercase text-3xl font-pragmatica-extended font-bold"
    >
      <span
        >{{ 2 - listItems.length }} more
        {{ 2 - listItems.length === 1 ? 'image' : 'images' }} required.</span
      >
    </div>
    <!-- GALLERY-->
    <div v-else class="h-full w-full flex items-center">
      <!-- LEFT WINDOW-->
      <div
        class="w-full h-full bg-whiteSmoke grid grid-cols-12 gap-x-6 xl:w-65% xl:mr-5%"
      >
        <!-- TEXT AND MOBILE PAGINATION SECTION -->
        <div
          class="relative col-start-2 col-end-7 h-85% self-center flex flex-col items-center justify-center text-center xl:col-start-4 xl:col-end-8 xl:justify-self-end xl:-ml-12 xl:h-80% xl:items-start xl:text-left"
        >
          <!-- TEXT SECTION -->
          <editor-tip-tap
            name="descriptionGalleryTitle"
            class="text-2xl leading-tight font-pragmatica-extended sm:text-3xl md:text-5xl lg:text-3.6rem"
          />
          <multiple-bordered-button
            primary-color-name="pureYellow"
            text-color-name="darkGrayishViolet"
            class="my-5 md:my-8"
          >
            <template #default>
              <editor-link
                v-slot="{ name, path }"
                name="buyLink"
                class="w-full h-full"
              >
                <cms-link :to="path" class="btn-text">
                  {{ name }}
                </cms-link>
              </editor-link>
            </template>
          </multiple-bordered-button>
          <editor-tip-tap
            name="descriptionGallerySubtitle"
            class="w-full text-base leading-tight sm:w-70% sm:text-xl md:text-1.4rem"
          />
          <!-- MOBILE PAGINATION -->
          <div
            class="mt-6 self-center flex items-center justify-center space-x-3 sm:mt-0 sm:absolute sm:bottom-0 sm:left-50% sm:transform sm:-translate-x-1/2 md:space-x-6 xl:hidden"
          >
            <button class="gallery-button transitioned" @click="slidePrevious">
              <fa :icon="icons.basic.leftArrow" class="transitioned" />
            </button>
            <p class="font-pragmatica-extended font-bold text-lg md:text-2xl">
              {{ leftActiveItemIndex + 1 }} / {{ listItems.length }}
            </p>
            <button class="gallery-button transitioned" @click="slideNext">
              <fa :icon="icons.basic.rightArrow" class="transitioned" />
            </button>
          </div>
        </div>
        <!-- IMAGE SECTION -->
        <div
          class="relative overflow-hidden col-start-7 col-end-12 h-85% self-center xl:col-start-8 xl:h-80%"
        >
          <transition-group :name="slideTranisitionName">
            <editor-list-item
              v-for="(item, index) in listItems"
              v-show="leftActiveItemIndex === index"
              :key="`${item.$id}-left`"
              class="slide"
              :item-id="item.$id"
            >
              <cms-image
                :media="item.image"
                class="w-full h-full object-contain object-center"
              />
            </editor-list-item>
          </transition-group>
        </div>
      </div>
      <!--      RIGHT WINDOW-->
      <div class="relative hidden w-1/4 h-70% bg-whiteSmoke xl:block xl:mr-5%">
        <!-- IMAGE SECTION -->
        <div class="relative overflow-hidden w-full h-full">
          <transition-group :name="slideTranisitionName">
            <editor-list-item
              v-for="(item, index) in listItems"
              v-show="rightActiveItemIndex === index"
              :key="`${item.$id}-left`"
              class="slide"
              :item-id="item.$id"
            >
              <cms-image
                :media="item.image"
                class="w-full h-full object-contain object-center"
              />
            </editor-list-item>
          </transition-group>
        </div>
        <!-- WEB PAGINATION -->
        <div
          class="absolute bottom-0 transform translate-y-7rem w-full flex items-center justify-between"
        >
          <div class="flex items-center justify-center space-x-6">
            <button class="gallery-button transitioned" @click="slidePrevious">
              <fa :icon="icons.basic.leftArrow" class="transitioned" />
            </button>
            <button class="gallery-button transitioned" @click="slideNext">
              <fa :icon="icons.basic.rightArrow" class="transitioned" />
            </button>
          </div>

          <p class="font-pragmatica-extended font-bold text-1.6rem">
            {{ leftActiveItemIndex + 1 }} / {{ listItems.length }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditorListItem from 'Editors/EditorListItem'
import EditorTipTap from 'Editors/EditorTipTap'
import MultipleBorderedButton from '@/components/ui/buttons/MultipleBorderedButton'
import EditorLink from 'Editors/EditorLink'
import FaIcons from '@/mixins/FaIcons'
import debounce from 'lodash.debounce'

export default {
  components: {
    EditorListItem,
    EditorTipTap,
    MultipleBorderedButton,
    EditorLink,
  },
  // DODAJ JEBENI DEBOUNCE
  mixins: [FaIcons],
  props: {
    listItems: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      leftActiveItemIndex: 0,
      galleryWidth: 0,
      slideModes: null,
      activeSlideMode: null,
      isTransitioning: false,
    }
  },

  computed: {
    isGalleryShown() {
      return this.listItems.length > 1
    },
    slideTranisitionName() {
      return this.activeSlideMode === this.slideModes.previous
        ? 'slide-right-spacing'
        : 'slide-left-spacing'
    },
    galleryHeight() {
      if (this.galleryWidth < 640) return Math.round(this.galleryWidth * 0.75)
      if (this.galleryWidth < 1024) return Math.round(this.galleryWidth * 0.65)
      return Math.round(this.galleryWidth * 0.45)
    },
    rightActiveItemIndex() {
      if (this.leftActiveItemIndex === this.listItems.length - 1) {
        return 0
      } else {
        return this.leftActiveItemIndex + 1
      }
    },
  },

  created() {
    const slideModes = {
      previous: 1,
      next: 2,
    }
    Object.freeze(slideModes)
    this.slideModes = slideModes
    this.activeSlideMode = slideModes.next
  },

  mounted() {
    this.calculateGalleryWidth()
  },

  methods: {
    slidePrevious: debounce(function () {
      if (this.isTransitioning) return
      this.isTransitioning = true
      this.activeSlideMode = this.slideModes.previous
      if (this.leftActiveItemIndex === 0) {
        this.leftActiveItemIndex = this.listItems.length - 1
      } else this.leftActiveItemIndex--
      this.isTransitioning = false
    }, 200),
    slideNext: debounce(function () {
      if (this.isTransitioning) return
      this.isTransitioning = true
      this.activeSlideMode = this.slideModes.next
      if (this.leftActiveItemIndex === this.listItems.length - 1) {
        this.leftActiveItemIndex = 0
      } else this.leftActiveItemIndex++
      this.isTransitioning = false
    }, 200),
    calculateGalleryWidth() {
      this.galleryWidth =
        this.$refs.descriptionGalleryContainer.getBoundingClientRect().width
    },
  },
}
</script>
<style scoped>
.gallery-button {
  @apply w-10 h-10 rounded-full text-2xl text-darkGrayishViolet bg-white border border-lightGrayishBlue flex-shrink-0 flex items-center justify-center;
}
.gallery-button:focus {
  @apply outline-none;
}

.gallery-button:hover {
  @apply bg-pureYellow border-pureYellow;
}

.slide {
  @apply w-full h-full absolute top-0 !important;
}

@media only screen and (min-width: 1280px) {
  .gallery-button {
    @apply w-16 h-16;
  }
  .gallery-button:hover svg {
    @apply transform scale-125;
  }
}
</style>
