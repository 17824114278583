var render, staticRenderFns
import script from "./InternationalDistributersSection.vue?vue&type=script&lang=js"
export * from "./InternationalDistributersSection.vue?vue&type=script&lang=js"
import style0 from "./InternationalDistributersSection.vue?vue&type=style&index=0&id=a4ae8764&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4ae8764",
  null
  
)

export default component.exports