<template>
  <editor-image name="coverImage" class="w-full h-screen" />
</template>
<script>
import EditorImage from 'Editors/EditorImage'
export default {
  components: {
    EditorImage,
  },
}
</script>
<style scoped>
::v-deep img {
  @apply w-full h-full object-center object-cover;
}
</style>
