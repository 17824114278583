<template>
  <div class="bg-darkGrayishViolet">
    <div
      class="content-container pt-8 pb-24 md:pt-20 md:pb-48 xl:pt-56 text-white"
    >
      <div class="xl:flex xl:items-start xl:justify-between">
        <div class="w-full sm:max-w-50rem xl:pt-7rem">
          <editor-tip-tap
            name="title"
            content-class="main-title mb-5 overflow-hidden font-extrabold leading-tight font-pragmatica-extended md:text-left md:mb-12"
          />
          <editor-tip-tap
            name="subtitle"
            content-class="main-subtitle text-pureYellow leading-tight font-pragmatica-extended md:mb-10"
          />
        </div>
        <editor-image
          name="image"
          class="ml-auto w-20rem h-27rem md:w-35rem md:h-47rem lg:h-60rem lg:w-53rem"
        />
      </div>

      <editor-list
        v-slot="{ item, index }"
        name="aboutList"
        :item-options="options"
        item-class="items-list--item"
        class="md:-mt-64 items-list"
      >
        <div
          :key="item.$id"
          class="w-full leading-relaxed md:w-1/2 md:leading-loose mb-16 md:mb-24 lg:mb-13rem"
          :class="[{ 'ml-auto': (index + 1) % 2 === 0 }]"
        >
          <p
            class="font-pragmatica-extended font-bold text-2xl mb-3 sm:text-2rem sm:mb-12"
          >
            {{ item.title }}
          </p>
          <div class="text-xl sm:text-1.6rem" v-html="item.description" />
        </div>
      </editor-list>
    </div>
  </div>
</template>
<script>
import EditorImage from 'Editors/EditorImage'
import EditorList from 'Editors/EditorList'
import EditorTipTap from 'Editors/EditorTipTap'

export default {
  components: {
    EditorImage,
    EditorList,
    EditorTipTap,
  },
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'tipTap',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .items-list {
  &--item {
    span {
      @apply font-pragmatica #{'!important'};
    }
    &:last-of-type {
      > div {
        @apply mb-0 #{'!important'};
      }
    }
  }
}
::v-deep img {
  @apply w-full h-full object-contain object-center;
}

::v-deep ul,
ol {
  @apply pl-10;
}

::v-deep ul li {
  @apply list-disc;
}

::v-deep ol li {
  @apply list-decimal;
}
</style>
