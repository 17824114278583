<script>
import SmartNeedleHeroSection from '@/components/sections/product-single-page/smart-needle-single-sections/SmartNeedleHeroSection'

export default {
  extends: SmartNeedleHeroSection,
  data() {
    return {
      bgColor: 'white',
      textColor: 'darkGrayishViolet',
      editorProductListName: 'smartCardPriceProductList',
      useLightHexagon: true,
    }
  },
}
</script>
