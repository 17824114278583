<template>
  <div class="view-container">
    <div class="content-container">
      <editor-tip-tap
        name="testimonialsTitle"
        content-class="main-title mb-12 overflow-hidden font-extrabold leading-tight font-pragmatica-extended md:mb-8.5rem"
      />
      <editor-tip-tap
        name="testimonialsSubtitle"
        class="main-subtitle mb-4 leading-tight font-pragmatica-extended md:mb-20"
      />

      <editor-list
        v-slot="{ item }"
        name="testimonials"
        :item-options="options"
        class="masonry"
        item-class="w-full inline-block mt-12 sm:mt-20 xl:mt-8.5"
      >
        <div>
          <div
            class="mb-5 text-2xl leading-tight font-pragmatica-extended md:text-2.4rem"
            v-html="item.title"
          />

          <div
            class="mb-10 text-xl leading-normal md:mb-16 md:text-1.6rem"
            v-html="item.description"
          />

          <div
            class="pb-6 text-xl leading-normal font-pragmatica-extended border-b border-lightGrayishBlue md:pb-10 md:text-1.6rem"
          >
            <p class="font-bold">
              {{ item.initials }}
              <span v-show="item.age">({{ item.age }})</span>
            </p>
            <p>
              {{ item.country }}<span v-show="item.city">/{{ item.city }}</span>
            </p>
          </div>
        </div>
      </editor-list>
    </div>
  </div>
</template>
<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorList from 'Editors/EditorList'
export default {
  components: {
    EditorTipTap,
    EditorList,
  },

  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Title',
          type: 'tipTap',
        },
        {
          name: 'description',
          label: 'Description',
          type: 'tipTap',
        },
        {
          name: 'initials',
          label: 'User initials',
          type: 'text',
        },
        {
          name: 'age',
          label: 'Age',
          type: 'text',
        },
        {
          name: 'country',
          label: 'Country',
          type: 'text',
        },
        {
          name: 'city',
          label: 'City',
          type: 'text',
        },
      ],
    }
  },
}
</script>
<style scoped>
.masonry {
  column-count: 1;
  column-gap: 0;
  column-fill: balance;
}
::v-deep ul,
ol {
  @apply pl-10;
}

::v-deep ul li {
  @apply list-disc;
}

::v-deep ol li {
  @apply list-decimal;
}

@media only screen and (min-width: 640px) {
  .masonry {
    column-count: 2;
    column-gap: 1.6rem;
  }
}

@media only screen and (min-width: 1024px) {
  .masonry {
    column-count: 3;
    column-gap: 2.5rem;
  }
}
</style>
