<template>
  <div
    class="content-container py-8 min-h-100vh-mobile-menu md:min-h-100vh-menu md:pt-40 md:pb-48"
  >
    <editor-tip-tap
      name="faqTitle"
      content-class="main-title overflow-hidden mb-12 font-extrabold leading-tight font-pragmatica-extended md:mb-7rem"
    />
    <editor-tip-tap
      name="faqSubtitle"
      content-class="main-subtitle mb-8 leading-tight font-pragmatica-extended md:mb-20"
    />
    <hr />
    <editor-list
      v-slot="{ item, index }"
      v-click-outside="onClickOutside"
      name="frequentlyAskedQuestions"
      :item-options="options"
    >
      <div class="overflow-hidden">
        <div
          class="flex justify-between items-center cursor-pointer"
          @click="toggleClickedQuestion(index)"
        >
          <p
            class="py-4 text-xl leading-relaxed font-pragmatica-extended font-bold md:py-8 md:text-1.6rem"
          >
            {{ item.question }}
          </p>
          <button
            class="ml-8 flex-shrink-0 h-12 w-12 rounded-full transform text-xl text-darkGrayishViolet transitioned focus:outline-none md:ml-12 md:text-1.6rem xl:ml-24"
            :class="{ 'rotate-90': index === clickedQuestionIndex }"
          >
            <fa :icon="icons.basic.rightArrow" />
          </button>
        </div>
        <hr />
        <div v-show="index === clickedQuestionIndex">
          <p
            class="py-5 w-4/5 md:w-2/3 mx-auto text-xl leading-relaxed cursor-default md:text-1.6rem md:py-8"
          >
            {{ item.answer }}
          </p>
          <hr />
        </div>
      </div>
    </editor-list>
  </div>
</template>
<script>
import EditorTipTap from 'Editors/EditorTipTap'
import EditorList from 'Editors/EditorList'
import vClickOutside from 'v-click-outside'
import FaIcons from '@/mixins/FaIcons'

export default {
  components: {
    EditorTipTap,
    EditorList,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [FaIcons],
  data() {
    return {
      clickedQuestionIndex: null,

      options: [
        {
          name: 'question',
          label: 'Pitanje',
          type: 'textarea',
        },
        {
          name: 'answer',
          label: 'Odgovor',
          type: 'textarea',
        },
      ],
    }
  },

  methods: {
    toggleClickedQuestion(index) {
      if (this.clickedQuestionIndex === index) {
        this.clickedQuestionIndex = null
        return
      }
      this.clickedQuestionIndex = index
    },
    onClickOutside() {
      this.clickedQuestionIndex = null
    },
  },
}
</script>
