<template>
  <div
    class="bg-hexagon-background bg-darkGrayishViolet bg-center bg-cover bg-no-repeat py-32 sm:bg-auto md:py-56 md:min-h-screen md:flex md:items-center md:justify-center"
  >
    <div
      class="content-container relative text-white flex items-center justify-center"
    >
      <!-- IMAGE -->
      <img
        class="w-40 h-27rem sm:w-20rem sm:h-45rem lg:w-35rem lg:h-72.8rem"
        src="/images/smart-needle/smart-needle-details.png"
      />
      <div
        class="absolute w-full top-0 left-0 h-full grid grid-cols-12 grid-rows-1 gap-2"
      >
        <!-- LEFT COLUMN -->
        <div
          class="col-start-1 col-end-5 flex flex-col justify-evenly items-end xl:col-start-2 xl:ml-6"
        >
          <div>
            <editor-tip-tap
              name="description1Title"
              class="description-title"
            />
            <editor-tip-tap name="description1Text" class="description-text" />
          </div>
          <div>
            <editor-tip-tap
              name="description2Title"
              class="description-title"
            />
            <editor-tip-tap name="description2Text" class="description-text" />
          </div>
        </div>

        <!-- RIGHT COLUMN -->
        <div
          class="relative col-start-8 col-end-13 flex flex-col justify-between items-start ml-2 pb-12 pt-6 sm:pb-20 sm:ml-4 lg:pb-32 lg:pt-10 xl:col-end-12"
        >
          <div>
            <editor-tip-tap
              name="description3Title"
              class="description-title"
            />
            <editor-tip-tap name="description3Text" class="description-text" />
          </div>
          <div class="self-end max-w-75% xl:mt-16">
            <editor-tip-tap
              name="description4Title"
              class="description-title"
            />
            <editor-tip-tap name="description4Text" class="description-text" />
          </div>
          <div>
            <editor-tip-tap
              name="description5Title"
              class="description-title"
            />
            <editor-tip-tap name="description5Text" class="description-text" />
          </div>

          <!-- CERTIFICATES -->
          <div
            class="absolute bottom-0 transform translate-y-1/2 flex items-start space-x-5 sm:space-x-8 lg:space-x-10"
          >
            <a
              v-tooltip="{
                content: certificateTooltipContent(252),
              }"
              :href="
                isCroatianLanguage
                  ? '/documents/certificate-252-hr.pdf'
                  : '/documents/certificate-252-en.pdf'
              "
              target="_blank"
            >
              <img
                src="/images/certificates/certificate252.png"
                class="transitioned h-24 w-32 md:h-32 md:w-40 xl:h-12.5rem xl:w-15rem transform hover:scale-105"
              />
            </a>

            <a
              v-tooltip="{
                content: certificateTooltipContent(251),
              }"
              :href="
                isCroatianLanguage
                  ? '/documents/certificate-251-hr.pdf'
                  : '/documents/certificate-251-en.pdf'
              "
              target="_blank"
            >
              <img
                src="/images/certificates/certificate251.png"
                class="transitioned h-24 w-32 md:h-32 md:w-40 xl:h-12.5rem xl:w-15rem transform hover:scale-105"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorTipTap from 'Editors/EditorTipTap'
import { VTooltip } from 'v-tooltip'
export default {
  components: {
    EditorTipTap,
  },

  directives: {
    tooltip: VTooltip,
  },

  computed: {
    isCroatianLanguage() {
      return this.$application.language.code2 === 'hr'
    },
  },

  methods: {
    certificateTooltipContent(number) {
      return `<p>${this.$t('hsc.certificates.certificate')} ${number}:
       <span class="font-semibold uppercase">${this.$t(
         `hsc.certificates.${number}`
       )}</span>
      <br/><span class="italic">${this.$t(
        'hsc.certificates.clickToOpen'
      )}</span>
      </p>`
    },
  },
}
</script>

<style scoped>
.description-title {
  @apply w-full font-pragmatica-extended leading-tight font-bold text-sm;
}

.description-text {
  @apply w-full text-xs;
}

::v-deep img {
  @apply object-center object-contain;
}
@media only screen and (min-width: 640px) {
  .description-title {
    @apply text-xl;
  }

  .description-text {
    @apply text-base;
  }
}

@media only screen and (min-width: 768px) {
  .description-title {
    @apply text-3xl;
  }

  .description-text {
    @apply text-xl;
  }
}

@media only screen and (min-width: 1024px) {
  .description-title {
    @apply text-2rem;
  }

  .description-text {
    @apply text-1.6rem;
  }
}
</style>
