<script>
import mixins from 'vue-typed-mixins'
import sectionView from '../mixins/sectionView'
import EditorContainer from '../mixins/EditorContainer'
import startCase from 'lodash/startCase'
import kebabCase from 'lodash/kebabCase'
import { WithPartialInject } from '../utils/withInject'
const dummyInject = WithPartialInject('dummyMode')

import desktopView from '~/components/sections/privacy-policy-page/PrivacyPolicySection.vue'

export default mixins(sectionView, EditorContainer, dummyInject).extend({
  name: 'SectionPrivacyPolicySection',

  components: {
		desktopView,
  },

  props: {
    product: {
      type: Object,
      default: null,
    },
    componentData: {
      type: Object,
      default: null,
    }
  },

  provide() {
    return {
      componentData: this.componentData,
      sectionName: this.$options.name,
    }
  },

  computed: {
    showMobileView() {
      return this.disableMobileView ? false : this.isFromMobile
    },
    hideSection() {
      if (this.contentEditableView) return false

      const { linkOptions } = this.componentData
      if (!linkOptions) return false

      return linkOptions.visibility === 'desktop'
    },
  },

  methods: {
    wrap(children) {
        const masterId = this.$options.propsData?.componentData?.master_id;
        const notEditableText = this.$t('core.childComponentInfo.notEditable')
        const banner = this.contentEditableView ? this.$createElement('article', {
        class: masterId ? 'banner absolute top-0 left-0 px-3 py-1 text-white bg-red-600 z-10': 'banner absolute top-0 left-0 px-3 py-1 text-white bg-blue-600 z-10'
      }, masterId ? `${ startCase('PrivacyPolicySection')} ${notEditableText}`: startCase('PrivacyPolicySection')) : undefined
      const border = this.contentEditableView ? 'border-t border-dashed border-blue-300' : ''
      const cursorNotAllowed = masterId ? 'hover:cursor-not-allowed': ''
      return this.$createElement('article', {
        class: [kebabCase(this.$options.name), 'relative', border, cursorNotAllowed].filter(Boolean).join(' ')
      }, [banner, children])
    }
  },

  render(h) {
    return this.wrap(h('desktop-view'))
  },
})
</script>

<style scoped>
.banner {
    transition: max-height 150ms, padding-top 150ms, padding-bottom 150ms;
    height: auto;
    overflow: hidden;
    max-height: 32px;
}

article.relative:hover > .banner {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}
</style>
