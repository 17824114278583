<script>
import HomePageSmartNeedleSection from '@/components/sections/home-page/HomePageSmartNeedleSection'
export default {
  extends: HomePageSmartNeedleSection,
  data() {
    return {
      bgColor: 'darkGrayishViolet',
      textColor: 'white',
      useLightHexagon: false,
    }
  },
}
</script>
